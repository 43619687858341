import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../img/JVSLogo-Ico.ico";

const Footer = () => {
  const [data, setData] = useState({
    title: "",
    Description: "",
    SocialMedia_Link: {
      Facebook: "",
      Instagram: "",
      Linkedin: "",
      Twitter: "",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-jvsbodyworks-website1.cloudfunctions.net/api/Footer");
        console.log("Fetched service data:", response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid footer bg-dark py-5 wow fadeIn" data-wow-delay="0.2s">
        <div className="container">
          <div className="row g-5 mb-5 align-items-center">
            <div className="col-lg-7">
              <div className="position-relative mx-auto"></div>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <div className="footer-item">
                  <h3 className="text-white mb-4">
                    <img src={logo} className="logoimg" alt="JVS Logo" />
                    {data.title}
                  </h3>
                  <p className="mb-3 text-white footerdescription">{data.Description}</p>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="d-flex align-items-center justify-content-center justify-content-lg-end">
                  {data.SocialMedia_Link.Facebook && (
                    <a className="btn btn-light btn-md-square me-3" href={data.SocialMedia_Link.Facebook} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook-f" />
                    </a>
                  )}
                  {data.SocialMedia_Link.Twitter && (
                    <a className="btn btn-light btn-md-square me-3" href={data.SocialMedia_Link.Twitter} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter" />
                    </a>
                  )}
                  {data.SocialMedia_Link.Instagram && (
                    <a className="btn btn-light btn-md-square me-3" href={data.SocialMedia_Link.Instagram} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-instagram" />
                    </a>
                  )}
                  {data.SocialMedia_Link.Linkedin && (
                    <a className="btn btn-light btn-md-square me-0" href={data.SocialMedia_Link.Linkedin} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="text-white mb-4">Quick Links</h4>
                <a href="/">Home</a>
                <a href="/about">About us</a>
                <a href="/service">Service</a>
                <a href="/contact">Contact Us</a>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="text-white mb-4">Products</h4>
                <a href="/PetroleumTankers">Petroleum Tankers</a>
                <a href="/WaterTanker">Water Tankers</a>
                <a href="/BituminTankers">Bitumin Tankers</a>
                <a href="/FuelBowsers">Fuel Browsers</a>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="text-white mb-4">Contact Info</h4>
                <a href="https://maps.app.goo.gl/14b2pwfhhbacK5RA7?g_st=com.google.maps.preview.copy">
                  <i className="fa fa-map-marker-alt text-primary me-2" /> No.1, Gomathi Amman Nagar,Tiruvallur, Ponneri,Chennai
                </a>
                <a href="mailto:info@jvsbw.com">
                  <i className="fas fa-envelope text-primary me-2" /> info@jvsbw.com
                </a>
                <a href="tel:+91 94443 11695">
                  <i className="fas fa-phone text-primary me-2" /> +91 94443 11695
                </a>
                <a href="tel:+91 91766 61695">
                  <i className="fas fa-phone text-primary me-2" /> +91 91766 61695
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <span className="text-body">
                <a href="/" className="border-bottom text-white">
                  <i className="fas fa-copyright text-light" /> JVS Body Works
                </a>
                
              </span>
            </div>
            <div className="col-md-6 text-center text-md-end text-body">
              Developed By{" "}
              <a className="border-bottom text-white" href="https://www.datadna.in/">
                DATADNA
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
